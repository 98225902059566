.slick-dots {
     top: 90% ;
 }
 .slider-img{
     width: 100% !important;
     height: 480px;
 }
 
 .slick-slide{
     height:auto !important;      
 }
 
 